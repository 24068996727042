import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  alpha,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  styled,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  AddRounded,
  BrokenImageRounded,
  CodeRounded,
  EditOutlined,
  FileDownloadOutlined,
  MoreVertRounded,
  SearchRounded,
} from '@mui/icons-material';
import { useDropzone } from 'react-dropzone';
import { useHistory } from 'react-router';
import Search from '../../Search';
import { apiBaseUrlV1 } from '../../../env';
import useSnackbar from '../../../hooks/useSnackbar';
import { AppsIcon, ListIcon, TrashIcon } from '../../../icons';
import {
  useCreateVisualMutation,
  useDeleteVisualMutation,
  useGetVisualAssetQuery,
  useGetVisualDocumentQuery,
  useGetVisualsQuery,
  useUpdateVisualMutation,
} from '../../../redux/services/visuals/api';
import { SwitchBtn, SwitchWrap } from '../../styles/switch';
import { EmptyBox, Bold, Light } from '../../styles/empty';
import InnerModal from './InnerModal';
import { ROUTES_PATH } from '../../../constants';
import { useAppSelector } from '../../../redux/store';
import { IAppState } from '../../../typescript/interfaces/appstate.interface';
import { VisualResponse } from '../../../redux/services/visuals/types';
import { isForbiddenError } from '../../../redux/utils';

const ModalComponent = styled(Dialog)({
  '& .MuiDialog-paper': {
    flexDirection: 'row',
    overflow: 'inherit',
    alignItems: 'stretch',
    width: '720px',
    minHeight: '500px',
    maxWidth: 'inherit',
    '@media(max-height: 600px)': {
      maxHeight: '90%',
      minHeight: 'auto',
    },
  },
});

const ContentWrap = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  maxHeight: '100%',
});

const Head = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
}));

const ThumbWrap = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: '#fff',
  minHeight: '260px',
  maxHeight: '320px',
  flex: 1,
  margin: theme.spacing(3),
  marginLeft: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(4),
  '& img': {
    height: 'auto',
    cursor: 'pointer',
    maxHeight: '100%',
    maxWidth: '100%',
  },
  '&:hover:after': {
    content: '"Change image"',
    position: 'absolute',
    display: 'block',
    padding: '0.5em',
    borderRadius: theme.shape.borderRadius,
    background: theme.palette.background.default,
    cursor: 'pointer',
    border: `1px solid ${theme.palette.text.secondary}`,
  },
  // '@media(max-height)'
}));

const Title = styled(DialogTitle)(({ theme }) => ({
  fontSize: '1.5rem',
  whiteSpace: 'nowrap',
  marginRight: theme.spacing(2),
  padding: '0 !important',
}));

const DetailTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.25rem',
  color: theme.palette.text.secondary,
  fontWeight: 600,
  marginRight: theme.spacing(2),
}));

const DetailText = styled(Typography)(({ theme }) => ({
  fontSize: '1.125rem',
  color: theme.palette.text.secondary,
  marginTop: 0,
}));

const Actions = styled(DialogActions)(({ theme }) => ({
  marginTop: 'auto',
  padding: theme.spacing(3),
}));

const SearchBtn = styled(IconButton)(({ theme }) => ({
  backgroundColor: 'transparent',
  marginRight: theme.spacing(1),
  border: `1px solid ${theme.palette.divider}`,
  color: theme.palette.mode === 'dark' ? '#fff' : theme.palette.secondary.main,
  '&:hover': {
    backgroundColor: 'transparent',
  },
  '&:focus': {
    backgroundColor: 'transparent',
  },
  '&:active': {
    backgroundColor: 'transparent',
  },
  '&.active': {
    backgroundColor: theme.palette.background.paper,
  },
}));

const AddBtn = styled(IconButton)(({ theme }) => ({
  backgroundColor: 'transparent',
  marginRight: theme.spacing(1),
  border: `1px solid ${theme.palette.divider}`,
  color: theme.palette.mode === 'dark' ? '#fff' : theme.palette.secondary.main,
  '&:hover': {
    backgroundColor: 'transparent',
  },
  '&:focus': {
    backgroundColor: 'transparent',
  },
  '&:active': {
    backgroundColor: 'transparent',
  },
  '&.active': {
    backgroundColor: theme.palette.background.paper,
  },
}));

const ListItem = styled(Box)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  fontSize: '1.125rem',
  '&.active': {
    backgroundColor: alpha(theme.palette.secondary.main, 0.15),
    fontWeight: 600,
  },
}));

const GridItem = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '120px',
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: '#fff',
  '&.active': {
    backgroundColor:
      theme.palette.mode === 'light'
        ? alpha(theme.palette.secondary.main, 0.15)
        : '#e9f1f7',
    borderColor: theme.palette.secondary.main,
  },
}));

const Dropzone = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  fontSize: '1.125rem',
  backgroundColor: alpha(theme.palette.secondary.main, 0.15),
  border: '1px dotted',
  borderRadius: theme.shape.borderRadius,
  margin: theme.spacing(2),
  marginLeft: 0,
}));

const EditButtonStyled = styled(Button)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  marginRight: '1rem',
  color:
    theme.palette.mode === 'dark'
      ? '#fff'
      : theme.palette.secondary.contrastText,
  backgroundColor: theme.palette.secondary.main,
  '&:hover': {
    backgroundColor: 'transparent',
    color: theme.palette.secondary.main,
  },
}));

const ImagePreviewStyled = styled('img')(() => ({
  maxWidth: '100%',
  maxHeight: '100%',
}));

const InnerHead = styled(Box)({
  display: 'flex',
  width: '100%',
  justifyContent: 'flex-end',
});

const GridWrap = styled(Box)({
  flex: 1,
  overflow: 'auto',
  display: 'block',
  alignItems: 'center',
});

const RightContentWrap = styled(Box)(({ theme }) => ({
  overflow: 'auto',
  flex: 1,
  paddingRight: theme.spacing(3),
  '@media(max-height: 600px)': {
    overflow: 'initial',
  },
}));

type FileWithPreview = {
  file: File;
  preview: string;
};

interface Props {
  open: boolean;
  onClose: any;
  pasteHandler?: (id: string) => void;
}

function getVisualAssetUrl(id, version) {
  return `${apiBaseUrlV1('structure/v1')}/visuals/${id}/asset/${version}`;
}

export const FORMDATA_ASSET = 'asset';
export const FORMDATA_DEFINITION = 'definition';

const MoreIconComponent = ({ menuOpen, handleClick }: any) => (
  <IconButton
    sx={{ ml: 'auto' }}
    id="action-button"
    aria-controls={menuOpen ? 'action-menu' : undefined}
    aria-haspopup="true"
    aria-expanded={menuOpen ? 'true' : undefined}
    onClick={handleClick}
    className="outlined"
  >
    <MoreVertRounded />
  </IconButton>
);

export const PreviewItemComponent = ({
  visual,
}: {
  visual: VisualResponse;
}) => {
  const { id, version } = visual;
  const { data: imageText, isLoading } = useGetVisualAssetQuery({
    id,
    version,
  });

  const image = useMemo(() => {
    const svg = new Blob([imageText], { type: 'image/svg+xml' });
    const url = URL.createObjectURL(svg);
    return url;
  }, [imageText]);

  if (isLoading || imageText === 'undefined')
    return (
      <BrokenImageRounded
        sx={{
          color: 'secondary.main',
          opacity: 0.75,
        }}
      />
    );

  return (
    <ImagePreviewStyled
      src={image}
      alt={visual.name}
      id={`ElementLibrary-${visual.id}`}
    />
  );
};

const Modal = (props: Props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { open, onClose, pasteHandler } = props;
  const [listView, setListView] = useState(false);
  const [isInnerModalOpened, setIsInnerModalClosed] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [searchExpand, setSearchExpand] = useState(false);
  const [activeVisualIdx, setActiveVisualIdx] = useState<number>(0);
  const [nextActiveVisualId, setNextActiveVisualId] = useState<string>(null);
  const [selectedVisuals, setSelectedItems] = useState<number[]>([]);
  const [acceptedAsset, setAcceptedAsset] = useState<FileWithPreview>();
  const [acceptedDefinition, setAcceptedDefinition] = useState<File>();
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [redirectActiveId, setRedirectActiveId] = useState<string>();
  const [isPolling, setIsPolling] = useState<boolean>(false);

  const { showError, showSuccess } = useSnackbar();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setConfirmDelete(false);
  };

  const {
    data: visuals = [],
    isSuccess: isFetched,
    isLoading: isVisualLoading,
  } = useGetVisualsQuery(undefined, { pollingInterval: isPolling ? 500 : 0 });

  const localVisuals = useMemo(
    () =>
      visuals
        .map((visual) => ({
          ...visual,
          image: getVisualAssetUrl(visual.id, visual.activeAssetVersion),
        }))
        .filter(
          (visual) =>
            !searchValue ||
            visual?.name?.toLowerCase().indexOf(searchValue?.toLowerCase()) >
              -1,
        ),
    [searchValue, visuals],
  );

  const { data: visualAssetData, isLoading: isVisualFetching } =
    useGetVisualAssetQuery(
      {
        id: localVisuals[activeVisualIdx]?.id,
        version: localVisuals[activeVisualIdx]?.version,
      },
      { skip: !localVisuals[activeVisualIdx]?.id },
    );
  const { data: visualDocumentData, isLoading: isDocumentFetching } =
    useGetVisualDocumentQuery(localVisuals[activeVisualIdx]?.id, {
      skip: !localVisuals[activeVisualIdx]?.id,
    });

  const handleRedirect = useCallback(() => {
    history.push(
      `${ROUTES_PATH.CREATE_VISUAL}/${localVisuals[activeVisualIdx]?.id}`,
    );
    // handle current uploaded image
  }, [activeVisualIdx, localVisuals]);

  const handleActive = (value: number) => {
    setActiveVisualIdx(value);
    setAcceptedAsset(null);
    setAcceptedDefinition(null);
  };

  const handleSelect = (value: number) => {
    setSelectedItems((prev) => {
      if (prev.indexOf(value) !== -1)
        return prev.filter((item) => item !== value);
      return [...prev, value];
    });
  };

  const onListView = () => {
    setListView(true);
  };

  const onGridView = () => {
    setListView(false);
  };

  const handleInnerModalOpen = () => {
    setIsInnerModalClosed(true);
    handleClose();
  };

  const handleInnerModalClose = () => {
    setIsInnerModalClosed(false);
  };

  const handleExpandSearch = () => {
    setSearchExpand(true);
  };

  const handleCollapseSearch = () => {
    setSearchExpand(false);
    setSearchValue('');
  };

  const handleSearchInput = (event) => {
    setSearchValue(event.target.value);
  };

  const handleAdd = () => {
    if (listView) {
      if (selectedVisuals.length > 0) {
        selectedVisuals.forEach((i) => pasteHandler(localVisuals[i].id));
      } else {
        pasteHandler(localVisuals[activeVisualIdx].id);
      }
    } else {
      pasteHandler(localVisuals[activeVisualIdx].id);
    }
    onClose();
  };

  const [
    createVisual,
    {
      error: createError,
      isSuccess: isCreated,
      isLoading: isCreating,
      data: createdVisual,
      reset: resetCreate,
    },
  ] = useCreateVisualMutation();

  const [
    updateVisual,
    {
      error: updateError,
      isSuccess: isUpdated,
      isLoading: isUpdating,
      data: updatedVisual,
      reset: resetUpdate,
    },
  ] = useUpdateVisualMutation();

  const [
    deleteVisual,
    {
      error: deleteError,
      isSuccess: isDeleted,
      isLoading: isDeleting,
      reset: resetDelete,
    },
  ] = useDeleteVisualMutation();

  const { getRootProps: getAssetRootProps, getInputProps: getAssetInputProps } =
    useDropzone({
      noKeyboard: true,
      accept: '.svg',
      disabled: isCreating || isDeleting || isUpdating,
      multiple: false,
      onDrop: async (acceptedFiles) => {
        const formData = new FormData();
        formData.append(FORMDATA_ASSET, acceptedFiles[0]);
        if (activeVisualIdx != null) {
          // Immediately try saving if we are updating an existing visual
          await updateVisual({
            id: localVisuals[activeVisualIdx].id,
            form: formData,
          });
          return;
        }
        if (acceptedDefinition != null) {
          // Immdediately try creating the visual if the definition is provided already
          formData.append(FORMDATA_DEFINITION, acceptedDefinition);
          await createVisual(formData);
          return;
        }
        setAcceptedAsset({
          file: acceptedFiles[0],
          preview: URL.createObjectURL(acceptedFiles[0]),
        });
      },
    });

  const handleDelete = () => {
    if (activeVisual?.id) {
      deleteVisual(activeVisual?.id);
    }
  };

  const delayPolling = () => {
    setTimeout(() => {
      setIsPolling(false);
    }, 10000);
  };

  useEffect(() => {
    if (!createError) return;
    if (isForbiddenError(createError)) {
      showError('You do not have permission to create visual');
    } else {
      showError('Failed to create visual');
    }
    resetCreate();
  }, [createError]);

  useEffect(() => {
    if (!updateError) return;
    if (isForbiddenError(updateError)) {
      showError('You do not have permission to create visual');
    } else {
      showError('Failed to update visual');
    }
    resetUpdate();
  }, [updateError]);

  useEffect(() => {
    if (!deleteError) return;
    if (isForbiddenError(deleteError)) {
      showError('You do not have permission to delete visual');
    } else {
      showError('Failed to delete visual');
    }
    resetDelete();
  }, [deleteError]);

  useEffect(() => {
    if (isCreated && createdVisual) {
      showSuccess(`Visual created: ${createdVisual.name}`);
      setNextActiveVisualId(createdVisual.id);
      resetCreate();
    }
    if (isUpdated && updatedVisual) {
      showSuccess(`Visual updated: ${updatedVisual.name}`);
      resetUpdate();
    }
    if (isDeleted) {
      showSuccess(`Visual deleted`);
      resetDelete();
      setConfirmDelete(false);
      handleClose();
    }
    if (isCreated || isUpdated || isDeleted) {
      // Hacky way of dealing with lateness of change propagation to visuals list query.
      // The mutation actually triggers an automatic refetch, but at that time,
      // the list query does not probably have the latest state.
      delayPolling();
      setIsPolling(true);
    }
  }, [isCreated, createdVisual, isUpdated, updatedVisual, isDeleted]);

  useEffect(() => {
    if (nextActiveVisualId && isFetched) {
      const createdIdx = localVisuals.findIndex(
        (it) => it.id === nextActiveVisualId,
      );
      if (createdIdx > 0) {
        setActiveVisualIdx(createdIdx);
        setNextActiveVisualId(null);
      }
    }
  }, [nextActiveVisualId, isFetched, localVisuals]);

  const activeVisual =
    localVisuals.length > 0 ? localVisuals[activeVisualIdx] : null;

  const handleConfirmOpen = () => {
    setConfirmDelete(true);
  };

  const handleDefinitionFileDownload = () => {
    const defBlob = new Blob([visualDocumentData], { type: 'text/plain' });
    const url = window.URL.createObjectURL(defBlob);
    const element = document.createElement('a');
    element.href = url;
    element.setAttribute(
      'download',
      `${localVisuals[activeVisualIdx].name}.yaml`,
    );

    document.body.appendChild(element);
    element.click();

    handleClose();
  };

  const handleImageFileDownload = () => {
    const imgBlob = new Blob([visualAssetData], { type: 'text/plain' });
    const url = window.URL.createObjectURL(imgBlob);
    const element = document.createElement('a');
    element.href = url;
    element.setAttribute(
      'download',
      `${localVisuals[activeVisualIdx].name}.svg`,
    );

    document.body.appendChild(element);
    element.click();

    handleClose();
  };

  const redirect = useAppSelector((state: IAppState) => state.redirect);

  useEffect(() => {
    if (redirect?.state?.selectedId)
      setRedirectActiveId(redirect?.state?.selectedId);
  }, [redirect]);

  useEffect(() => {
    if (localVisuals?.length > 0 && redirectActiveId) {
      setActiveVisualIdx(
        localVisuals.map(({ id }) => id).indexOf(redirectActiveId),
      );
      setRedirectActiveId(undefined);
    }
  }, [redirectActiveId, localVisuals]);

  return (
    <ModalComponent
      aria-labelledby="element-library-title"
      aria-describedby="element-library-description"
      open={open}
      onClose={onClose}
      scroll="paper"
      fullScreen={isMobile}
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          flexDirection: { xs: 'column', sm: 'row' },
        }}
      >
        <ContentWrap
          sx={{
            borderRight: '1px solid',
            borderColor: 'divider',
            flex: { xs: 1, sm: '1 1 60%' },
            maxHeight: '100%',
            overflow: { xs: 'auto', sm: 'inherit' },
          }}
        >
          <Head>
            {!searchExpand && (
              <Title id="element-library-title">
                Element library{' '}
                {listView &&
                  selectedVisuals.length > 0 &&
                  `(${selectedVisuals.length})`}
              </Title>
            )}
            <InnerHead>
              {searchExpand ? (
                <Search
                  placeholder="Search"
                  value={searchValue}
                  onSearch={handleSearchInput}
                  onCancel={handleCollapseSearch}
                  autoFocus={searchExpand}
                  sx={{ mr: 1 }}
                />
              ) : (
                <Tooltip title="Search">
                  <SearchBtn
                    onClick={handleExpandSearch}
                    className={searchExpand && 'active'}
                    name="search"
                    aria-label="search"
                  >
                    <SearchRounded />
                  </SearchBtn>
                </Tooltip>
              )}
              <Tooltip title="Create element">
                <AddBtn
                  onClick={() => {
                    setIsEdit(false);
                    handleInnerModalOpen();
                  }}
                  name="add-visual"
                  aria-label="add visual"
                >
                  <AddRounded />
                </AddBtn>
              </Tooltip>
              <SwitchWrap sx={{ ml: 0 }}>
                <Tooltip title="Grid view">
                  <SwitchBtn
                    onClick={onGridView}
                    className={!listView && 'active'}
                    color="inherit"
                  >
                    <AppsIcon />
                  </SwitchBtn>
                </Tooltip>
                <Tooltip title="List view">
                  <SwitchBtn
                    onClick={onListView}
                    className={listView && 'active'}
                    color="inherit"
                  >
                    <ListIcon />
                  </SwitchBtn>
                </Tooltip>
              </SwitchWrap>
            </InnerHead>
          </Head>
          <GridWrap
            sx={{
              p: listView ? 0 : 3,
            }}
          >
            <Grid container spacing={listView ? 0 : 2}>
              {localVisuals.length > 0 ? (
                localVisuals.map((visual, index) => (
                  <React.Fragment key={visual.id}>
                    {listView ? (
                      <Grid item xs={12}>
                        <ListItem
                          className={index === activeVisualIdx ? 'active' : ''}
                          onClick={() => handleActive(index)}
                          onDoubleClick={() => {
                            pasteHandler(localVisuals[index].id);
                            onClose();
                          }}
                        >
                          <Checkbox
                            sx={{ mr: 1, ml: '2px' }}
                            onClick={() => handleSelect(index)}
                            checked={selectedVisuals.indexOf(index) !== -1}
                          />
                          {visual.name}
                        </ListItem>
                      </Grid>
                    ) : (
                      <Grid item xs={6} sm={4}>
                        <GridItem
                          className={index === activeVisualIdx ? 'active' : ''}
                          onClick={() => handleActive(index)}
                          onDoubleClick={() => {
                            pasteHandler(localVisuals[index].id);
                            onClose();
                          }}
                        >
                          <PreviewItemComponent visual={visual} />
                        </GridItem>
                      </Grid>
                    )}
                  </React.Fragment>
                ))
              ) : (
                <Grid item xs={12}>
                  <EmptyBox sx={{ height: '100%', mt: listView ? 2 : 0 }}>
                    <SearchRounded className="icon" />
                    <Bold>No element found</Bold>
                    <Light>Try searching different word</Light>
                  </EmptyBox>
                </Grid>
              )}
            </Grid>
          </GridWrap>
        </ContentWrap>
        <ContentWrap
          sx={{
            pl: { sm: 3, xs: 0 },
            flex: { xs: 0, sm: '1 1 40%' },
            maxWidth: { sm: '40%', xs: '100%' },
            maxHeight: { xs: '40%', sm: '100%' },
          }}
        >
          <Box
            sx={{
              overflow: 'auto',
              pl: { sm: 0, xs: 3 },
              pt: { sm: 0, xs: 2 },
            }}
          >
            {localVisuals.length > 0 && (
              <ThumbWrap
                sx={{ display: { xs: 'none', sm: 'flex' } }}
                {...getAssetRootProps()}
              >
                <input {...getAssetInputProps()} />
                {(activeVisual || acceptedAsset) && (
                  <PreviewItemComponent visual={activeVisual} />
                )}
                {!acceptedAsset && !activeVisual && (
                  <Dropzone>Drag or click to select an image</Dropzone>
                )}
              </ThumbWrap>
            )}
            {localVisuals.length > 0 && (
              <RightContentWrap>
                {activeVisual ? (
                  <>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <DetailTitle noWrap>{activeVisual.name}</DetailTitle>
                      <MoreIconComponent
                        menuOpen={menuOpen}
                        handleClick={handleClick}
                      />
                      <Menu
                        id="action-menu"
                        anchorEl={anchorEl}
                        open={menuOpen}
                        onClose={handleClose}
                        MenuListProps={{
                          'aria-labelledby': 'action-button',
                        }}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                      >
                        <MenuItem
                          onClick={() => {
                            setIsEdit(true);
                            handleInnerModalOpen();
                          }}
                        >
                          <ListItemIcon>
                            <EditOutlined />
                          </ListItemIcon>
                          Edit
                        </MenuItem>
                        <MenuItem
                          onClick={() => handleRedirect()}
                          disabled={isVisualLoading}
                        >
                          <ListItemIcon>
                            <CodeRounded />
                          </ListItemIcon>
                          Open in editor
                        </MenuItem>
                        <MenuItem
                          onClick={handleDefinitionFileDownload}
                          disabled={isDocumentFetching}
                        >
                          <ListItemIcon>
                            <FileDownloadOutlined />
                          </ListItemIcon>
                          Download definition file
                        </MenuItem>
                        <MenuItem
                          onClick={handleImageFileDownload}
                          disabled={isVisualFetching}
                        >
                          <ListItemIcon>
                            <FileDownloadOutlined />
                          </ListItemIcon>
                          Download SVG
                        </MenuItem>
                        <Tooltip
                          placement="top"
                          PopperProps={{
                            sx: {
                              zIndex: 99999999,
                              '& .MuiTooltip-arrow:before': {
                                color: 'background.default',
                                border: '1px solid',
                                borderColor: 'divider',
                              },
                              '& .MuiTooltip-tooltip': {
                                backgroundColor: 'background.default',
                                border: '1px solid',
                                borderColor: 'divider',
                                boxShadow:
                                  '0px 6px 16px rgba(74, 85, 104, 0.08)',
                              },
                            },
                          }}
                          title={
                            <Button
                              disabled={isDeleting}
                              color="error"
                              onClick={handleDelete}
                            >
                              Confirm deletion
                            </Button>
                          }
                          open={confirmDelete}
                        >
                          <MenuItem
                            sx={{ color: 'error.main' }}
                            onClick={handleConfirmOpen}
                          >
                            <ListItemIcon>
                              <TrashIcon />
                            </ListItemIcon>
                            Delete element
                          </MenuItem>
                        </Tooltip>
                      </Menu>
                    </Box>
                    <DetailText>{activeVisual.description}</DetailText>
                  </>
                ) : null}
              </RightContentWrap>
            )}
          </Box>
          <Actions>
            <Button
              id="AddElement-cancel_button"
              onClick={onClose}
              variant="outlined"
              color="inherit"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleAdd}
              disabled={!activeVisual}
              id="AddElement-add_button"
            >
              Add{' '}
              {listView && selectedVisuals.length > 1 ? 'selected' : 'element'}
            </Button>
          </Actions>
        </ContentWrap>
      </Box>
      <InnerModal
        open={isInnerModalOpened}
        onClose={handleInnerModalClose}
        visual={isEdit ? localVisuals[activeVisualIdx] : undefined}
      />
    </ModalComponent>
  );
};

Modal.defaultProps = {
  pasteHandler: () => {},
};

export default Modal;
