import React from 'react';
import { Box } from '@mui/material';
import { XYPosition } from 'react-flow-renderer';

interface Props {
  className?: string;
  maxValue: number;
  progress: number;
  position?: XYPosition;
  size?: { width: number; height: number };
}

const HoriontalProgress = (props: Props) => {
  const {
    progress: propsProgress,
    maxValue,
    className,
    position,
    size,
  } = props;
  const progress = propsProgress || 0;

  const { width, height } = size;

  return (
    <Box
      className={className}
      sx={{
        display: 'flex',
        flexDirection: 'column-reverse',
        position: 'absolute',
        top: position?.y,
        left: position?.x + 150,
        '& svg': {},
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width + 2}
        height={height + 2}
      >
        <g id="progressbar" transform="translate(0 0)">
          <rect
            id="bar"
            width={width}
            height={height}
            rx="4"
            transform="translate(0 0)"
            fill="#4c9fc8"
            opacity="0.15"
          />
          <rect
            id="progress"
            width={height}
            height={(progress / maxValue) * width}
            rx="4"
            transform={`translate(0 ${height}) rotate(-90)`}
            fill="#4c9fc8"
          />
        </g>
      </svg>
    </Box>
  );
};

HoriontalProgress.defaultProps = {
  className: '',
  position: {
    x: '-50%',
    y: '-15%',
  },
  size: {
    width: 150,
    height: 22,
  },
};

export default HoriontalProgress;
